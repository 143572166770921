<template>
  <PageLayout
    :bannerSrc="bannerSrc"
    :pageTitle="pageTitle"
    :pageSubtitle="pageSubtitle"
    pageTitleDividerClass="primary"
    :color="color"
    layoutType="x"
    :hideLogo="true"
  >
    <div class="content-wrapper pt-6 pb-12">
      <div class="coverageList">
        <div class="py-lg-10">
          <v-row class="justify-space-between align-center pb-lg-10">
            <img class="coverageLogo" :src="pressCoveragelist.logo" alt="" />
            <div
              class="coverageDateTime"
              v-html="pressCoveragelist.dateTime"
            ></div>
          </v-row>
          <div
            class="py-lg-10 py-5 coverageTitle"
            v-html="pressCoveragelist.title"
          ></div>
          <div class="coverageContent" v-html="pressCoveragelist.content"></div>
          <v-row class="justify-space-around align-center py-lg-10 py-5">
            <button
              class="customButton primary secondary--text"
              @click="actionButtonBackCoverage"
            >
              BACK
            </button>
            <div class="" style="font-weight: bold">
              {{ pressCoveragelist.button.current }} /
              {{ pressCoveragelist.button.total }}
            </div>
            <button
              class="customButton primary secondary--text"
              @click="actionButtonNextCoverage"
            >
              NEXT
            </button>
          </v-row>
        </div>
      </div>
    </div>
    <!-- {{ pressCoveragelist.button }} -->
  </PageLayout>
</template>

<script>
  import PageLayout from '@/views/layout/PageLayout';
  import { Configuration /*uiHelper */ } from '@/core/utils';

  export default {
    name: 'pressCoverageDetailTemplate',
    components: {
      PageLayout
      // AppPDFViewer
    },
    props: {
      bannerSrc: {
        type: String,
        default: ''
      },
      pageTitle: {
        type: String,
        default: ''
      },
      pageSubtitle: {
        type: String,
        default: ''
      },
      color: {
        type: String,
        default: 'surface'
      },
      topic: {
        type: String,
        default: ''
      },
      pressCoveragelist: {
        default: () => []
      }
    },
    data: () => ({
      spacesUrl: Configuration.value('spacesUrl'),
      assetEnvironment: Configuration.value('assetEnvironment'),
      noPDFContent: false
    }),
    methods: {
      actionButtonNextCoverage() {
        this.$emit('action-button-next-coverage');
      },
      actionButtonBackCoverage() {
        this.$emit('action-button-back-coverage');
      }
    }
  };
</script>

<style lang="scss"></style>
