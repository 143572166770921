<template>
  <div>
    <PressCoverageDetailTemplate
      bannerSrc="/assets/image/pressCoverage_banner.png"
      :pageTitle="$t('label.pressCoverage')"
      :pageSubtitle="$t('label.pressCoverageDetial')"
      :isPDF="true"
      topic="pressCoverageList"
      :pressCoveragelist="pressCoveragelist"
      @action-button-next-coverage="actionNextCoverage"
      @action-button-back-coverage="actionBackCoverage"
    ></PressCoverageDetailTemplate>
    <!-- {{ pressCoveragelist.button }} -->
  </div>
</template>

<script>
  import PressCoverageDetailTemplate from '@/views/components/pressCoverage/pressCoverageDetailTemplate';
  import { PRESS_COVERAGE_GET_PRESS_COVERAGE } from '@/core/store/press-coverage.module';

  export default {
    name: 'PressCoverageDetail',
    components: {
      PressCoverageDetailTemplate
    },
    data: () => ({
      content: null,
      pressCoveragelist: []
    }),
    props: {
      id: {
        default: null
      }
    },
    created() {
      this.initPressCoverage();
    },
    computed: {
      getPressCoverageComplete() {
        return this.$store.state.pressCoverage.pressCoverage.complete;
      }
    },
    watch: {
      getPressCoverageComplete() {
        this.getPressCoverages();
      },
      updatePressCoverageComplete() {
        this.getPressCoverages();
      }
    },
    mounted() {
      this.getPressCoverages();
    },
    methods: {
      getPressCoverages() {
        let id = this.id;
        this.$store
          .dispatch(PRESS_COVERAGE_GET_PRESS_COVERAGE, { id })
          .then(() => {
            this.passPressCoverage();
          });
      },
      initPressCoverage() {
        this.getPressCoverages();
      },
      passPressCoverage() {
        let response = this.$store.state.pressCoverage.pressCoverage;
        if (response.complete) {
          let data = response.data;
          this.pressCoveragelist = data;
        }
      },
      actionNextCoverage() {
        let response = this.$store.state.pressCoverage.pressCoverage;
        let id = response.data.button.next;
        if (id != 0) {
          id + 1;
          this.$store
            .dispatch(PRESS_COVERAGE_GET_PRESS_COVERAGE, { id })
            .then(() => {
              this.passPressCoverage();
            });
        }
      },
      actionBackCoverage() {
        let response = this.$store.state.pressCoverage.pressCoverage;
        let id = response.data.button.previous;
        if (id != 0) {
          id - 1;
          this.$store
            .dispatch(PRESS_COVERAGE_GET_PRESS_COVERAGE, { id })
            .then(() => {
              this.passPressCoverage();
            });
        } else {
          this.passPressCoverage();
        }
      }
    }
  };
</script>

<style></style>
